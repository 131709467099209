import "masonry-layout/dist/masonry.pkgd";
import {MutationObserverFactory, ResizeObserverFactory} from "./observation";
import {autoRegister, register, resolve} from "../container";
import Masonry from "masonry-layout";

export const ANIMATION_DURATION = 500;

export class GridLayout {
    private readonly parentElement: Element;
    private readonly gridElementSelector: string;
    private mutationObserver: MutationObserver;
    private resizeObserver: ResizeObserver;
    private masonElement: Masonry;

    public constructor(
        parent: Element,
        private options: Masonry.Options,
        private masonElementFactory: MasonElementFactory,
        private resizeObserverFactory: ResizeObserverFactory,
        private mutationObserverFactory: MutationObserverFactory
    ) {
        this.parentElement = parent;
        this.gridElementSelector = options.itemSelector ?? "";
    }

    public initLayout(): void {
        this.masonElement = this.masonElementFactory.create(this.parentElement, this.options);
        this.mutationObserver = this.mutationObserverFactory.create((mutations: MutationRecord[]) => this.addOrRemoveElements(mutations));
        this.mutationObserver.observe(this.parentElement, {childList: true});

        this.resizeObserver = this.resizeObserverFactory.create(() => this.updateLayout());

        this.observeResizeOn(Array.from(this.parentElement.querySelectorAll<Element>(this.gridElementSelector)));
    }

    public updateLayout(): void {
        this.masonElement.layout!();
    }

    private append(elements: Element[]): void {
        if (elements.isEmpty()) {
            return;
        }

        this.masonElement.appended!(elements);
        this.observeResizeOn(elements);
    }

    private remove(elements: Element[]): void {
        if (elements.length > 0) {
            this.updateLayout();
            this.unobserveResizeOn(elements);
        }
    }

    private observeResizeOn(elements: Element[]): void {
        elements.forEach((element) => this.resizeObserver.observe(element));
    }

    private unobserveResizeOn(elements: Element[]): void {
        elements.forEach((element) => this.resizeObserver.unobserve(element));
    }

    private addOrRemoveElements(mutations: MutationRecord[]): void {
        const newElements: Element[] = [];
        const removedElements: Element[] = [];
        mutations.forEach((mutation: MutationRecord) => {
            this.filterForGridElements(mutation.addedNodes).forEach(e => newElements.push(e));
            this.filterForGridElements(mutation.removedNodes).forEach(e => removedElements.push(e));
        });
        this.append(newElements);
        this.remove(removedElements);
    }

    private filterForGridElements(nodes: NodeList): Element[] {
        return Array.from(nodes)
            .filter(it => it.nodeType === Node.ELEMENT_NODE)
            .map(it => it as Element)
            .filter(it => it.matches(this.gridElementSelector));
    }
}

export class MasonElementFactory {
    public create(element: Element, options: Masonry.Options): Masonry {
        return new Masonry(element, options);
    }
}

register(MasonElementFactory);

@autoRegister()
export class GridLayoutFactory {

    public constructor(
        private masonElementFactory: MasonElementFactory = resolve(MasonElementFactory),
        private resizeObserverFactory: ResizeObserverFactory = resolve(ResizeObserverFactory),
        private mutationObserverFactory: MutationObserverFactory = resolve(MutationObserverFactory)) {
    }

    public create(
        parent: Element,
        elementSelector: string,
        columnWidthElementSelector?: string
    ): GridLayout {
        const options: Masonry.Options = {
            itemSelector: elementSelector,
            columnWidth: columnWidthElementSelector ?? elementSelector,
            transitionDuration: ANIMATION_DURATION
        };
        return new GridLayout(parent, options, this.masonElementFactory, this.resizeObserverFactory, this.mutationObserverFactory);
    }
}