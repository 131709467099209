import {property, query, queryAll, state} from "lit/decorators.js";
import {resolve} from "../../../container";
import {Timeout} from "../../../common/timeout";
import {FETCH_SUGGESTIONS_DEBOUNCE, MIN_SUGGESTION_LENGTH} from "./constants";
import {SearchFacade} from "./searchFacade";
import {ManagingResources} from "../../../common/lifetime";
import type {PropertyValues, TemplateResult} from "lit";
import {html, LitElement} from "lit";
import {FeedService} from "../feedService";
import {classMap} from "lit/directives/class-map.js";
import {LanguagesService} from "../../../common/languages";
import {CLICK, eventOccurredOutside, KEYS} from "../../../common/utils/events";
import {GLOBAL} from "../../../common/globals";
import {unsafeHTML} from "lit/directives/unsafe-html.js";
import {Resolution} from "../../../common/resolution";
import {BOOTSTRAP} from "../../../common/resolutionConstants";
import {escapedPatternRegExp} from "../../../bootstrap/common/strings";
import {schedule} from "../../../common/utils/promises";


export abstract class SearchSlotWithAutocomplete extends ManagingResources(LitElement) {
    @property({attribute: "placeholder"})
    public placeholder: string;

    @query("input", true)
    protected input: HTMLInputElement;
    @query("button[type='reset']", true)
    private resetButton: HTMLButtonElement;
    @queryAll(".suggestion-item")
    private suggestionItems: NodeListOf<HTMLElement>;

    @state()
    private suggestions: string[] = [];
    @state()
    private selectedSuggestionIndex: number | null = null;

    private pendingInput: Promise<void> | undefined;
    private closeSuggestionsOnClickOutside: (event: Event) => void;

    protected constructor(
        private timeout: Timeout = resolve(Timeout),
        protected searchFacade: SearchFacade = resolve(SearchFacade),
        private feedService: FeedService = resolve(FeedService),
        private languageService: LanguagesService = resolve(LanguagesService),
        private resolution: Resolution = resolve(Resolution)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.closeSuggestionsOnClickOutside = (event: Event) => {
            if (eventOccurredOutside(event, this)) {
                this.closeSuggestions();
            }
            return CLICK.CONTINUE_PROPAGATION;
        };
    }

    public render(): TemplateResult {
        return html`
            <form class="content-search-slot" data-tracking-label="search-slot" @submit=${this.handleSubmit}>
                <div class="content-search-input">
                    <input id="content-search"
                           type="text"
                           class="form-control"
                           placeholder=${this.placeholder}
                           autocomplete="off"
                           @input=${this.handleInput}
                           @click=${this.triggerSuggestions}
                           @keydown=${this.handleKeydown}
                    >
                    ${this.renderSuggestions()}
                    <button type="reset" style="display: none;" data-tracking-label="search-slot-reset" @click=${this.handleReset}></button>
                </div>
                <button type="submit" class="content-search-button" data-tracking-label="search-submit"></button>
            </form>
        `;
    }

    private renderSuggestions(): TemplateResult {
        const suggestionItems = this.suggestions.map((suggestion, index) => this.renderSuggestionItem(suggestion, index));
        const classes = classMap({
            "suggestion-container": true,
            open: this.suggestions.length > 0
        });
        return html`
            <div class=${classes}>${suggestionItems}</div>`;
    }

    private renderSuggestionItem(suggestion: string, index: number): TemplateResult {
        const regex = escapedPatternRegExp(this.inputValueWithoutSpecialChars(), "i");
        const highlighted = suggestion.replace(regex, "<span class='suggestion'>$&</span>");
        const classes = classMap(({
            "suggestion-item": true,
            hovered: index === this.selectedSuggestionIndex
        }));

        return html`
            <div class=${classes}
                 data-value=${suggestion}
                 @click=${this.handleSuggestionClick}>
                <span>${unsafeHTML(highlighted)}</span>
            </div>`;
    }

    protected updated(_changedProperties: PropertyValues): void {
        super.updated(_changedProperties);

        this.updateResetButtonVisibility();
    }

    protected abstract triggerSearch(): void;

    private handleReset(): void {
        this.input.value = "";
        this.updateResetButtonVisibility();
        this.input.focus();
        this.afterReset();
    }

    protected abstract afterReset(): void;

    private handleSubmit(event: SubmitEvent): void {
        event.preventDefault();
        this.triggerSearch();
    }

    private handleInput(): void {
        this.cancelPendingInput();
        this.updateResetButtonVisibility();
        this.pendingInput = this.timeout.delay(() => void this.triggerSuggestions(), FETCH_SUGGESTIONS_DEBOUNCE);
    }

    protected cancelPendingInput(): void {
        if (this.pendingInput) {
            this.timeout.cancel(this.pendingInput);
        }
    }

    protected updateResetButtonVisibility(): void {
        if (this.input.value) {
            this.resetButton.show();
        } else {
            this.resetButton.hide();
        }
    }

    private handleKeydown(event: KeyboardEvent): void {
        if (!this.isSuggestionKeyboardInteraction(event)) {
            return;
        }

        event.preventDefault();

        if (event.key === KEYS.ENTER && this.selectedSuggestionIndex !== null) {
            this.suggestionSelected(this.suggestionItems[this.selectedSuggestionIndex]);
        } else if (event.key === KEYS.ARROW_DOWN) {
            this.selectedSuggestionIndex = this.selectedSuggestionIndex === null
                ? 0
                : (this.selectedSuggestionIndex + 1) % this.suggestions.length;
        } else if (event.key === KEYS.ARROW_UP) {
            this.selectedSuggestionIndex = this.selectedSuggestionIndex === null
                ? this.suggestions.length - 1
                : (this.selectedSuggestionIndex + this.suggestions.length - 1) % this.suggestions.length;
        } else if (event.key === KEYS.ESCAPE) {
            this.closeSuggestions();
        }
    }

    private isSuggestionKeyboardInteraction(event: KeyboardEvent): boolean {
        if (this.suggestions.isEmpty()) {
            return false;
        }

        if (this.selectedSuggestionIndex === null && event.key === KEYS.ENTER) {
            return false;
        }

        return [KEYS.ARROW_DOWN, KEYS.ARROW_UP, KEYS.ENTER, KEYS.ESCAPE].includes(event.key);
    }

    protected closeSuggestions(): void {
        this.clearSuggestions();
        GLOBAL.bodyElement().removeEventListener("click", this.closeSuggestionsOnClickOutside);
    }

    private openSuggestions(): void {
        GLOBAL.bodyElement().addEventListener("click", this.closeSuggestionsOnClickOutside);
    }

    private handleSuggestionClick(event: Event): void {
        this.suggestionSelected(event.currentTarget as HTMLElement);
    }

    private suggestionSelected(selection: HTMLElement): void {
        this.input.value = selection.getAttribute("data-value")!;
        this.clearSuggestions();
        this.triggerSearch();
        this.input.focus();
    }

    private async triggerSuggestions(): Promise<void> {
        if (!this.inputValidForSuggestions()) {
            this.clearSuggestions();
            return;
        }

        const sources = await schedule(this.searchFacade.allSources()).as("all-sources");
        const suggestionsCount = this.resolution.upTo(BOOTSTRAP.SM) ? 6 : 10;
        const response = await this.feedService.suggestions({
            lang: this.languageService.activeLanguageId(),
            sources: sources,
            limit: suggestionsCount,
            searchPhrase: this.inputValueWithoutSpecialChars()
        });
        this.suggestions = response.values;
        this.openSuggestions();
    }

    private clearSuggestions(): void {
        this.suggestions = [];
        this.selectedSuggestionIndex = null;
    }

    private inputValidForSuggestions(): boolean {
        const input = this.inputValueWithoutSpecialChars();
        return input.length >= MIN_SUGGESTION_LENGTH && input.split(/\s+/).length === 1;
    }

    private inputValueWithoutSpecialChars(): string {
        return this.input.value.replace(/[^a-zäöüA-ZÄÖÜß0-9-]/g, " ");
    }
}