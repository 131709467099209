import {html, LitElement, type TemplateResult} from "lit";
import {customElement} from "lit/decorators.js";
import {unsafeHTML} from "lit/directives/unsafe-html.js";
import Styles from "./disclaimerDialog.lit.scss";
import {CLOSE_DIALOG_EVENT} from "./modalDialog";
import {eopCustomEvent} from "../../../common/utils/events";

@customElement("eop-disclaimer-dialog")
export class DisclaimerDialog extends LitElement {

    public static readonly styles = Styles;

    public constructor(
        private headline: string,
        private content: string,
        private url: string,
        private acceptLabel: string,
        private declineLabel: string
    ) {
        super();
    }

    public render(): TemplateResult {
        this.classList.add("disclaimer-dialog");
        return html`
            <h2>${this.headline}</h2>
            <div>${unsafeHTML(this.content)}</div>
            <div class=disclaimer-footer>
                <a href=${this.url} target=_blank class="button-ok primary">
                    ${this.acceptLabel}
                </a>
                <button type=button class="button-cancel secondary" @click=${this.close}>
                    ${this.declineLabel}
                </button>
            </div>
        `;
    }

    private close(): void {
        this.dispatchEvent(eopCustomEvent(CLOSE_DIALOG_EVENT));
    }
}

export namespace DisclaimerDialogFactory {
    export function create(
        headline: string,
        content: string,
        url: string,
        acceptLabel: string,
        declineLabel: string
    ): DisclaimerDialog {
        return new DisclaimerDialog(headline, content, url, acceptLabel, declineLabel);
    }
}