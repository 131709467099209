import type {ContentEntryResponse, FilterDataResponse, PageResponse} from "../feedService";
import {VisitRecord} from "./visitRecord";
import type {PropertyMap} from "../../../common/utils/objects";
import {type Filters, type SearchFilterData} from "./searchFacade";
import {resolve} from "../../../container";
import type {FeedChannel} from "../feedCommons";
import {FeedEntry} from "../feedEntry";


export type FocusCallback = () => void;
export type ActivationCallback = (active: boolean) => void;
export type NewDataCallback = (entries: FeedEntry[], newCount: number) => void;


export type SearchTileData = {
    entry: FeedEntry;
    groupName: string;
}

export abstract class SearchGroup {
    public name: string;
    public sources: string[];
    public active: boolean;
    public entries: FeedEntry[];
    public hasMoreEntries: boolean;
    public totalCount: number;
    public applicableFilterData: SearchFilterData[];
    public appliedFilters: Filters;
    public targetEntryCount: number;

    private activationCallbacks: ActivationCallback[];
    private focusCallbacks: FocusCallback[];
    private newDataCallbacks: NewDataCallback[];

    protected constructor(name: string, sources: string[]) {
        this.name = name;
        this.sources = sources;
        this.active = false;
        this.entries = [];
        this.hasMoreEntries = false;
        this.totalCount = 0;
        this.appliedFilters = new Map();
        this.targetEntryCount = 0;
        this.applicableFilterData = [];
        this.activationCallbacks = [];
        this.focusCallbacks = [];
        this.newDataCallbacks = [];
    }

    public offset(): number {
        return this.entries.length;
    }

    public setActive(active: boolean): void {
        this.active = active;
        this.notifyActivation();
    }

    public onActivation(callback: ActivationCallback): void {
        this.activationCallbacks.push(callback);
    }

    private notifyActivation(): void {
        for (const callback of this.activationCallbacks) {
            callback(this.active);
        }
    }

    public focus(): void {
        this.notifyFocus();
    }

    public onFocus(callback: FocusCallback): void {
        this.focusCallbacks.push(callback);
    }

    private notifyFocus(): void {
        for (const callback of this.focusCallbacks) {
            callback();
        }
    }

    public applicableFiltersFrom(filters: Filters): Filters {
        const applicableFilters = new Map();
        filters.forEach((values, category) => {
            if (this.applicableFilterData.some(data => data.id === category)) {
                applicableFilters.set(category, values.clone());
            }
        });
        return applicableFilters;
    }

    public onNewData(callback: NewDataCallback): void {
        this.newDataCallbacks.push(callback);
    }

    protected notifyNewData(newCounter: number): void {
        for (const callback of this.newDataCallbacks) {
            callback(this.entries, newCounter);
        }
    }

    public updateFrom(response: PageResponse, targetEntryCount: number = 0): void {
        const visitedURLs = this.getVisitedUrls();
        this.entries = response.entries.map((entry: ContentEntryResponse) => this.mapEntry(entry, visitedURLs));
        this.hasMoreEntries = response.entries.length < response.totalCount;
        this.totalCount = response.totalCount;
        const newCounter = this.computeNew(response.newCount ?? 0);
        this.applicableFilterData = this.mapFilterData(response.filterData);
        this.targetEntryCount = targetEntryCount;
        this.notifyNewData(newCounter);
    }

    public extendWith(response: PageResponse, targetNewEntryCount: number = 0): void {
        const visitedUrls = this.getVisitedUrls();
        const newEntries = response.entries.map((entry: ContentEntryResponse) => this.mapEntry(entry, visitedUrls));
        this.entries = this.entries.concat(newEntries);
        this.hasMoreEntries = this.entries.length < response.totalCount;
        const newCounter = this.computeNew(response.newCount ?? 0);
        this.targetEntryCount += targetNewEntryCount;
        this.notifyNewData(newCounter);
    }

    public updateFilterDataFrom(filterData: FilterDataResponse[]): void {
        this.applicableFilterData = this.mapFilterData(filterData);
    }

    public applyFilters(selected: Filters): void {
        const appliedFilters = this.applicableFiltersFrom(selected);
        if (appliedFilters !== this.appliedFilters) {
            this.appliedFilters = appliedFilters;
        }
    }

    public updateFilters(selected: Filters, filters: Filters): void {
        const appliedFilters = this.applicableFiltersFrom(filters);
        if (appliedFilters !== this.appliedFilters) {
            this.appliedFilters = appliedFilters;
        }
    }

    private computeNew(newByAge: number): number {
        const newButRecentlyVisited = this.entries
            .filter(entry => entry.data.isNew && entry.recentlyVisited === true)
            .length;
        return newByAge - newButRecentlyVisited;
    }

    protected abstract getVisitedUrls(): string[];

    protected abstract mapFilterData(filterData: FilterDataResponse[]): SearchFilterData[];

    protected abstract mapEntry(entry: ContentEntryResponse, visitedURLs: string[]): FeedEntry;
}

export class DefaultSearchGroup extends SearchGroup {
    public constructor(
        name: string,
        private sourcesProperties: PropertyMap<FeedChannel>,
        private visitRecord: VisitRecord = resolve(VisitRecord)
    ) {
        super(name, Object.keys(sourcesProperties));
    }

    protected getVisitedUrls(): string[] {
        return this.visitRecord.getVisitedURLs();
    }

    protected mapFilterData(filterData: FilterDataResponse[]): SearchFilterData[] {
        return filterData
            .filter(category => category.matchingSources.includesAll(this.sources))
            .map(category => ({
                id: "filter_" + category.id,
                label: category.label,
                filters: category.values
            }));
    }

    protected mapEntry(entryResponse: ContentEntryResponse, visitedURLs: string[]): FeedEntry {
        return FeedEntry.fromResponse(entryResponse)
            .inChannelContext(this.sourcesProperties)
            .beingRecentlyVisited(entryResponse.url ? visitedURLs.includes(entryResponse.url) : false);
    }
}